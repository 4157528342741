import { withRouter } from 'react-router-dom';
import PrimaryButton from './PrimaryButton';
import LinkButton from './LinkButton';
import withWidth from '@material-ui/core/withWidth';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { globalStyles } from '../style/gotheme';
import FeatureList from './FeatureList';
import MultiColumnFeatureList from './MultiColumnFeatureList';
import VerticalFeatureList from './VerticalFeatureList';
import VerticalList from './VerticalList';
import Testimonial from './Testimonial';
import PageRow from './PageRow';
import FeatureCard from './FeatureCard';
import RollingText from './RollingText';

const styles = theme => {
  return Object.assign({
    manager: {
      margin: 5,
      borderRadius: 16,
      backgroundColor: 'rgba(239, 249, 255, 0.03)',
      display: 'flex',
      padding: 24
    },
    featureImageContainer: {
      width: 120,
      textAlign: 'center'
    },
    featureImage: {
      height: '80px',
      marginRight: 30
    },
    mainHeading: {
      fontFamily: "rajdhani",
      fontWeight: 'normal',
      color: 'white',
      margin: 0,
      textAlign: 'center',
      marginBottom: 24,
      padding: 0,
      fontSize: '40px'
    },
    featureHeading: {
      fontFamily: "rajdhani",
      fontWeight: 'normal',
      color: 'white',
      margin: 0,
      width: 180,
      padding: 0,
      fontSize: '32px'
    },
    subHeadingText: {
      fontFamily: "roboto",
      fontWeight: 300,
      textAlign: 'center',
      color: 'white',
      margin: '0px 0px 12px 0px',
      padding: 0,
      fontSize: '18px',
      lineHeight: '28px'
    },
    trialText: {
      fontFamily: "roboto",
      fontWeight: 300,
      textAlign: 'center',
      color: 'white',
      marginTop: 12,
      padding: 0,
      fontSize: '12px'
    },
    completeGraphic: {
      width: 408,
      backgroundColor: 'white',
      height: 95,
      textAlign: 'center',
      float: 'left',
      paddingTop: 45,
      borderRadius: 8,
      boxShadow: '0px 6px 12px #00000029',
      '&:hover': {
        cursor: 'pointer'
      }
    },
    smallCompleteGraphic: {
      display: 'block',
      margin: '0 auto',
      maxWidth: 408,
      backgroundColor: 'white',
      height: 95,
      textAlign: 'center',
      marginBottom: 20,
      paddingTop: 45,
      borderRadius: 8,
      boxShadow: '0px 6px 12px #00000029',
      '&:hover': {
        cursor: 'pointer'
      }
    }
  }, globalStyles)
};

class Home extends Component {

  goToGrafSync = () => {
    window.location.href = "https://graphifi.com/grafsync";
  }

  goToEasyGraph = () => {
    window.location.href = "https://graphifi.com/easygraph";
  }

  goToGraphologi = (page) => {
    if (page === "Contact") {
      this.props.history.push("contact")
    }
    else if (page === "Pricing") {
      this.props.history.push("pricing")
    }
    else {
      window.location.href = "https://graphologi.graphifi.com/gs/signup";
    }
  }


  render() {

    const { classes, width } = this.props;
    const isLarge = width === "lg" || width === "xl";
    const headingVariant = width === "xs" ? "h2" : "h1";

    return (
      <div>
        <PageRow showGradient={!isLarge} spaceBottom="40px">
          <div style={{ paddingTop: 60 }}>
            <h1 className={classes.mainHeading}>Create semantic knowledge graphs{isLarge && <br />} built on standards</h1>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
              <div className={classes.manager}>
                <div className={classes.featureImageContainer}>
                  <img className={classes.featureImage} src={"/images/vocabulary-manager.svg"} alt="" />
                </div>
                <h2 className={classes.featureHeading}>Taxonomy Management</h2>
              </div>
              <div className={classes.manager}>
                <div className={classes.featureImageContainer}>
                  <img className={classes.featureImage} src={"/images/ontology-manager.svg"} alt="" />
                </div>
                <h2 className={classes.featureHeading}>Ontology Management</h2>
              </div>
              <div className={classes.manager}>
                <div className={classes.featureImageContainer}>
                  <img className={classes.featureImage} src={"/images/data-manager.svg"} alt="" />
                </div>
                <h2 className={classes.featureHeading}>Data Management</h2>
              </div>
            </div>
            <div style={{
              marginTop: 30,
              marginBottom: 30,
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center'
            }}>
              <span style={{ margin: '0px 10px 10px 0px' }}>
                <PrimaryButton fixedWidth={true} onClick={() => this.goToGraphologi()}>Try for Free *</PrimaryButton>
              </span>
            </div>
            <Typography className={classes.subHeadingText}>Graphologi provides standards based management capabilities<br />for taxonomies, ontologies, and large sets of data.</Typography>
            <div style={{ textAlign: 'center', marginTop: 40 }}>
              <LinkButton onClick={() => this.goToGraphologi("Contact")}>Request Demo</LinkButton>
            </div>
            <Typography className={classes.trialText}>* Trials are 30 day and have all features available. No credit card needed.</Typography>
          </div>
        </PageRow >
        <PageRow color="#F2F7FF" spaceTop="100px">
          <div>
            <div style={{ marginBottom: 50 }}>
              <Typography variant={headingVariant} align="center">Built for the Enterprise</Typography>
              <Typography className={classes.mainSubHeading} align="center">Graphologi is designed from the ground up to meet the needs of your organisation.</Typography>
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
              <FeatureCard
                icon="corporate_fare.png"
                title="Enterprise Class"
                description="Providing all of the features and capabilities that you expect and need in an organisation." />
              <FeatureCard
                icon="security.png"
                title="Secure"
                description="Built with security as a priority you can be confident in Graphifi with our ISO27001 certification." />
              <FeatureCard
                icon="diagonal_line.png"
                title="Scalable"
                description="Manage millions of taxonomy concepts, huge ontologies and large sets of data." />
              <FeatureCard
                icon="license.png"
                title="Compliant"
                description="Built upon W3C semantic standards means your data is interoperable and easily transferable." />
              <FeatureCard
                icon="credit_card_heart.png"
                title="Affordable"
                description="A range of subscriptions and pricing options to meet your needs." />
            </div>
            <div style={{ textAlign: 'center', marginTop: 40 }}>
              <PrimaryButton onClick={() => this.goToGraphologi("Pricing")}>View Pricing</PrimaryButton>
            </div>
          </div>
        </PageRow>
        <PageRow spaceTop="100px">
          <div>
            <img src="images/go-green-corner.svg" alt="" style={{
              position: 'absolute',
              right: 0,
              bottom: 0
            }} />
            <RollingText
              items={[
                {
                  title: "Supercharge your AI using knowledge graphs",
                  description: "Knowledge graphs can help make AI more correct by acting as a source of truth to query."
                },
                {
                  title: "Power auto-classification of content",
                  description: "Taxonomies and ontologies allow for accurate and precise classification of content, providing valuable metadata."
                },
                {
                  title: "Create the foundations of a semantic layer",
                  description: "Connect and integrate your systems with unified semantics to provide standardised understanding."
                }
              ]}
            />
          </div>
        </PageRow>
        <PageRow
          color="#F2F7FF"
          spaceTop="100px"
        >
          <div style={{
            display: width === "md" || width === "lg" ? 'flex' : 'block'
          }}>
            <div style={{
              width: width === "md" || width === "lg" ? '50%' : '100%'
            }
            }>
              <Typography variant={headingVariant} align="left" style={{ marginBottom: 40 }}>Making it Easy</Typography>
              <VerticalList
                items={[
                  "A variety of purchasing options gives you flexibility to start small and grow.",
                  "Comprehensive features and standards compliance to allow you to do what you need to do.",
                  "Simple to get going yet able to support the most sophisticated requirements.",
                  "Real-time collaboration makes it simple for teams to work together.",
                  "A support team dedicated to helping you to get the best from Graphologi.",
                  "Easy integration with other Graphifi products."
                ]} />
            </div>
            <div style={{
              marginLeft: width === "md" || width === "lg" ? '100px' : '0px',
              marginTop: width === "md" || width === "lg" ? '0px' : '50px',
              textAlign: 'center'
            }}>
              <img src="images/info-1.svg" style={{ maxWidth: '100%' }} alt="" />
            </div>
          </div>
        </PageRow>
        <PageRow spaceTop="100px">
          <div>
            <div id="features" style={{ marginBottom: 50 }}>
              <Typography variant={headingVariant} align="center">Features</Typography>
            </div>
            <div style={{ marginBottom: 36 }}>
              <Grid container>
                <Grid item xs={12} md={4}>
                  <FeatureList
                    image="VocabularyManager.svg"
                    title="Vocabulary Manager"
                    description="Comprehensive features included out of the box."
                    items={[
                      "Based on SKOS standards",
                      "Full SKOS and SKOS-XL support",
                      "Collaborative editing of taxonomies",
                      "Workflow and task management",
                      "Allow SMEs to make suggestions",
                      "Enrich your data using ontologies",
                      "Templates to control the display and entry of data (SHACL)",
                      "Annotate your data (reification)",
                      "Quality checks",
                      "Customisable spreadsheet export and import",
                      "Advanced search across taxonomies",
                      "Use and render markdown, MathML, HTML and SVG and image links",
                      "Bulk edit concepts",
                      "Visualise a taxonomy",
                      "Connect to DBpedia",
                      "Link between projects"
                    ]} />
                </Grid>
                <Grid item xs={12} md={4}>
                  <FeatureList
                    image="OntologyManager.svg"
                    title="Ontology Manager"
                    description="A simple to use ontology management experience."
                    items={[
                      "Based on OWL2 standard",
                      "Manage multiple ontologies within a project",
                      "Collaborative editing of ontologies",
                      "Support for restrictions and complex expressions",
                      "Use SKOS annotations and custom annotation properties",
                      "Advanced search across ontologies",
                      "Visualise an ontology",
                      "Quality checks",
                      "Support for deprecation",
                      "OBO ontologies support",
                      "Import and manage ontologies with 100s of thousands of classes",
                      "Handle ontologies with 10s of thousands of subclasses"
                    ]} />
                </Grid>
                <Grid item xs={12} md={4}>
                  <FeatureList
                    image="DataManager.svg"
                    title="Data Manager"
                    description="Manage your data and connect it together to create enhanced semantics."
                    items={[
                      "Based on RDF, giving standards compliance",
                      "Create semantically rich data using ontologies",
                      "Templates to control the display and entry of data (SHACL)",
                      "Annotate your data (reification)",
                      "Workflow and task management",
                      "Collaborative editing of data",
                      "Customisable spreadsheet export and import",
                      "Advanced search across all of your data",
                      "Bulk edit data",
                      "Visualise the connections in your data",
                      "Use and render markdown, MathML, HTML and SVG and image links",
                      "Connect to DBpedia",
                      "Link between projects"
                    ]} />
                </Grid>
              </Grid>
            </div>
            <div>
              <MultiColumnFeatureList
                image="AdditionalFeatures.svg"
                title="Additional Features"
                description="Application-wide capabilities."
                items={[
                  "Full support for multiple languages",
                  "REST API for integration",
                  "Webhook integration",
                  "Create project versions and query via the API",
                  "Run predefined reports",
                  "Create custom reports",
                  "Ability to import and export data in various formats",
                  "Project snapshots",
                  "SPARQL query console",
                  "Project level management of data",
                  "Full change history for all data with the ability to filter and download",
                  "Configurable permissions",
                  "Statistical data about your projects"
                ]} />
            </div>
          </div>
        </PageRow>
        <PageRow color="#F2F7FF" spaceBottom="60px" spaceTop="100px">
          <div>
            <div id="verticals" style={{ marginBottom: 30 }}>
              <Typography variant={headingVariant} align="center">Verticals</Typography>
              <Typography className={classes.mainSubHeading} align="center">Taxonomy, ontology and data management are crucial to many different areas of business.</Typography>
              <Typography className={classes.mainSubHeading} align="center">Whether you are in life sciences, finance, education, or any other sector, Graphologi can help.</Typography>
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
              <VerticalFeatureList
                image="life-vertical.png"
                title="Life Sciences"
                description="Create innovative and valuable data solutions through connected data."
                items={[
                  "Drive research and FAIR solutions through taxonomies and ontologies",
                  "Create you own ontologies and map against existing ontologies such as CHEBI, MONDO or The Drug Ontology",
                  "Special support for OBO ontologies",
                  "Manage large data - over 1 million classes"
                ]} />
              <VerticalFeatureList
                image="ecommerce-vertical.png"
                title="eCommerce and Retail"
                description="Improve how you organise and manage product information, classifications and metadata."
                items={[
                  "Manage taxonomies to use for classifying your products",
                  "Handle multiple languages easily to ensure you can localise your output",
                  "Model and maintain product metadata models for use across your organisation",
                  "Store rich metadata about products"
                ]} />
              <VerticalFeatureList
                image="media-vertical.png"
                title="Media and Publishing"
                description="Make assets more discoverable and reusable to maximise value."
                items={[
                  "Easily model the metadata you need to store about your assets",
                  "Manage taxonomies to define controlled lists for use in tagging",
                  "Connect your data together to provide data for enhanced semantic search and provide users with a better experience"
                ]} />
              <VerticalFeatureList
                image="finance-vertical.png"
                title="Finance"
                description="Mitigate risk, improve compliance and understand the relationships between products and customers"
                items={[
                  "Manage how information connects together to understand and mitigate risk",
                  "Model data lineage",
                  "Improve compliance by managing data about regulatory requirements",
                  "Model and manage how products and customers fit together and drive recommendations"
                ]} />
              <VerticalFeatureList
                image="education-vertical.png"
                title="Education"
                description="Drive innovation in learning using semantically rich information"
                items={[
                  "Define subject classifications for use on learning content",
                  "Create and manage learning objectives and learning paths",
                  "Innovate in the creation of products driven by semantically rich information",
                  "Recommend content for learners"
                ]} />
              <VerticalFeatureList
                image="health-vertical.png"
                title="Healthcare"
                description="Create smart solutions for health and improve patient interaction"
                items={[
                  "Create quality connected information to drive intelligent applications",
                  "Tag content to help discovery and drive search of medical information",
                  "Provide information in multiple languages",
                  "Recommend content and further patient learning"
                ]} />
            </div>
          </div>
        </PageRow >
        <PageRow spaceBottom="60px" spaceTop="100px">
          <div id="customers" style={{ marginBottom: 30 }}>
            <Typography variant={headingVariant} align="center">Our Customers</Typography>
          </div>
          <div style={width !== "xs" && width !== "sm" ? { width: 800, margin: '0 auto' } : {}}>
            <Typography className={classes.mainSubHeading} align="center">Graphologi customers range from small startups to the largest organisations and span an ever increasing set of business domains that include: education, publishing, health, information providers, software, legal, fintech and search.</Typography>
            <Typography className={classes.mainSubHeading} align="center">Some of our customers are listed below.</Typography>
          </div>
          <Grid container justify="center" spacing={4} style={{ marginTop: 30 }}>
            <Grid item xs={12} sm={6} md={4} style={{ textAlign: 'center' }}>
              <a style={{ display: 'block' }} href="https://www.mongodb.com/" rel="noreferrer" target="_blank">
                <img style={{ width: 240, paddingTop: 20 }} src="/images/mongodb.png" alt="MongoDB" />
              </a>
            </Grid>
            <Grid item xs={12} sm={6} md={4} style={{ textAlign: 'center' }}>
              <a style={{ display: 'block', fontWeight: 500 }} href="https://www.cdc.gov/" rel="noreferrer" alt="CDC" target="_blank"><span style={{ fontSize: 48 }}>CDC</span><br /><span style={{ fontSize: 24 }}>Centers for Disease Control<br />and Prevention</span></a>
            </Grid>
            <Grid item xs={12} sm={6} md={4} style={{ textAlign: 'center' }}>
              <a style={{ display: 'block' }} href="https://neuralalpha.com/" rel="noreferrer" target="_blank">
                <img style={{ maxHeight: 100, maxWidth: '100%' }} src="/images/neural-alpha-logo.png" alt="Neural Alpha" />
              </a>
            </Grid>
            <Grid item xs={12} sm={6} md={4} style={{ textAlign: 'center' }}>
              <a style={{ display: 'block' }} href="https://hdn.nl/" rel="noreferrer" target="_blank">
                <img style={{ maxHeight: 100, maxWidth: '100%' }} src="/images/vereniginghdn_logo.jpg" alt="HDN" />
              </a>
            </Grid>
            <Grid item xs={6} sm={6} md={4} style={{ textAlign: 'center' }}>
              <a style={{ display: 'block' }} href="https://www.dodsgroup.com/" rel="noreferrer" target="_blank">
                <img style={{ maxHeight: 100, maxWidth: '100%' }} src="/images/dods-logo.png" alt="Dods Group" />
              </a>
            </Grid>
            <Grid item xs={6} sm={6} md={4} style={{ textAlign: 'center' }}>
              <a style={{ display: 'block', marginTop: 24 }} href="https://brizodata.com/" rel="noreferrer" target="_blank">
                <img style={{ maxHeight: 100, maxWidth: '100%' }} src="/images/brizo.svg" alt="Brizo Foodmetrics" />
              </a>
            </Grid>
          </Grid>
        </PageRow>
        <PageRow color="#F2F7FF" spaceTop="100px" spaceBottom="10px">
          <div id="testimonials" style={{ marginBottom: 50 }}>
            <Typography variant={headingVariant} align="center">What our Customers Say</Typography>
          </div>
          <Testimonial
            image="healthplace-logo-dark.svg"
            name="Matthew Inamdar"
            border
            role="Chief Technology Officer"
            words={
              [
                "After reviewing the other tools available in the taxonomy space, we decided to go with Graphologi for managing our vocabularies and ontologies due to their incredibly useful features, adoption of open standards and great customer service.",
                "They're fully SKOS compliant and provide many useful features such as a SPARQL query interface, API integrations and a suggestions workflow. Our in-house tooling has been built on top of Graphologi as we've confidently adopted it as a part of our core tech stack.",
                "It has been the perfect tool, growing with us as we started with a single vocabulary to now managing multiple vocabularies augmented with an ontology to express the complex relationships between them. The team has always been extremely helpful, and it's a joy working with them."
              ]}
          />
          <Testimonial
            direction="row-reverse"
            image="neural-alpha-logo.png"
            name="Dan Burke-Ward"
            border
            role="ESG Researcher"
            words={
              [
                "Graphologi allows us to explore multidimensional, complex and interlinked taxonomies without fear that our tool will let us down. We’re able to easily encompass the depth of information we want to store and to then interact with it effortlessly.",
                "Graphologi is easy to train new users on and has a very intuitive interface including detailed logs which we can use for version control.",
                "I’m fairly new in the area of taxonomic and ontology management and Graphologi has made it a breeze to get up to speed."
              ]}
          />
        </PageRow>
        <PageRow spaceBottom="120px" spaceTop="100px">
          <div>
            <Typography variant={headingVariant} align="center" style={{ marginBottom: 40 }}>Create a Complete Semantic Layer</Typography>
            <Typography className={classes.mainSubHeading} align="center">Graphologi can easily integrate with other Graphifi products - <a href="https://www.graphifi.com/easygraph">EasyGraph</a> and <a href="https://www.graphifi.com/graphai">GraphAI</a>, to create a complete<br />and scalable semantic layer solution to power search, analytics, AI and more.</Typography>
            <div style={width !== "xs" && width !== "sm" ? { maxWidth: 1000, margin: '0 auto' } : {}}>
              <div style={{ margin: '40px auto 0px auto', maxWidth: '100%' }}>
                <img src="/images/semantic-layer.svg" alt="" />
              </div>
              <div style={{ margin: '40px auto 40px auto', textAlign: 'center' }}>
                <PrimaryButton onClick={() => this.goToGraphologi("Contact")}>Chat with us to find out more</PrimaryButton>
              </div>
            </div>
          </div>
        </PageRow>
        <PageRow color="#F2F7FF" spaceBottom="60px" spaceTop="80px">
          <div>
            <Grid container>
              <Grid item xs={12}>
                <div style={{
                  margin: '0px auto 30px auto',
                  maxWidth: '90%',
                  width: width === "xs" ? '377px' : '675px',
                  borderRadius: 4,
                  textAlign: 'center',
                  padding: 4,
                  background: 'linear-gradient(252.4deg, #1C4B8C 0%, #D4FF53 99.54%)'
                }}>
                  <iframe
                    width={width === "xs" ? 280 : 675}
                    style={{ borderRadius: 4, maxWidth: '100%', transform: 'translateY(2px)' }}
                    height={width === "xs" ? 157 : 378}
                    src="https://www.youtube.com/embed/a8upwF9tzUI"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope" allowFullScreen></iframe>
                </div>
                <div style={width !== "xs" && width !== "sm" ? { width: 675, margin: '0 auto' } : {}}>
                  <Typography variant={headingVariant} align="left">Content Graphs</Typography>
                  <Typography paragraph className={classes.mainSubHeading} align="left">Where Content Meets Knowledge Graphs.</Typography>
                  <Typography paragraph variant="body1" align="left">There are many organisations with large amounts of content that they want to reuse or get better value from. One approach to solving this problem is to create a 'content graph' - a combination of a knowledge graph and content - which allows you to better understand your content and what it contains, find it and develop new and innovative ways to provide that content to users.</Typography>
                  <Typography paragraph variant="body1" align="left">Graphologi can be integrated with applications such as <a target="_new" href="https://graphifi.com/partners">Fonto</a>, which provides structured content tooling, to create an environment for authors to easily write and manage content as part of building that content graph.</Typography>
                  <Typography variant="body1" align="left">You can see an example of Graphologi and Fonto working together in this video.</Typography>
                </div>
              </Grid>
            </Grid>
          </div>
        </PageRow>
      </div >
    );
  }
}

export default withRouter(withWidth()(withStyles(styles)(Home)));