import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { globalStyles as styles } from '../style/gotheme';

class AnnotationInformation extends Component {

  render() {

    const { classes } = this.props;

    return (
      <div>
        <Typography paragraph variant="body1">In certain circumstances it can be very useful to know more about the information we are managing. For instance, where did the information come from, or from what date does the information apply.</Typography>
        <Typography paragraph variant="body1">For example, let’s say there is information such as:</Typography>
        <Typography paragraph variant="body1" align="center">The height of the Eiffel Tower is 300 metres</Typography>
        <Typography paragraph variant="body1">Where did this information come from? Well, one possible source might be Britannica:</Typography>
        <Typography paragraph variant="body1" align="center"><a className={classes.wpLink} href="https://www.britannica.com/topic/Eiffel-Tower-Paris-France" target="_new">https://www.britannica.com/topic/Eiffel-Tower-Paris-France</a></Typography>
        <Typography paragraph variant="body1">If we are storing this data in Graphologi it might be useful to also record that it came from Britannica. Having provenance like this can be very valuable. If the source is reliable a user of the information can make an assessment as to their level of trust in it. Or it may be the case that attribution is important such as citing Britannica in this example.</Typography>
        <Typography paragraph variant="body1">An example of where the date of information is useful to record is the change in length of the ship Silver Spirit. This ship was extended in 2018 from 195.8 to 210.7 metres - <a className={classes.wpLink} href="https://en.wikipedia.org/wiki/Silver_Spirit_(ship)" target="_new">https://en.wikipedia.org/wiki/Silver_Spirit_(ship)</a>. So, if we have the information:</Typography>
        <Typography paragraph variant="body1" align="center">The Silver Spirit has a length of 210.7 metres</Typography>
        <Typography paragraph variant="body1">It would be useful to record that this only applies from 2018. You might store both lengths with appropriate date annotations.</Typography>
        <Typography paragraph variant="body1">It is important to understand that annotations should be used to add detail about statements. They should not be used to model the domain itself.</Typography>
        <Typography paragraph variant="body1">Annotations can be used on taxonomies and datasets in Graphologi.</Typography>
        <Typography paragraph variant="body1">Annotations are done via templates. Templates allow a user to create sets of properties for use in various situations. In general you will want to create multiple templates - templates for the domain itself (so including properties from our example such as ‘Length’ or ‘Height’) and templates for annotation (so including properties such as ‘Stated By’ or ‘Applies From Date’).</Typography>
        <Typography paragraph variant="body1">Depending upon how you want to approach things it might make sense to create another ontology for annotation properties to keep a level of separation from any domain ontologies. There may be existing ontologies that you want to use for annotation which you can import into Graphologi.</Typography>
        <Typography paragraph variant="body1">An example template for our domain might look like the following in Graphologi (where we have already created the ontology).</Typography>
        <img alt="" className={classes.wpImage} style={{ width: 600 }} src="/images/annotation2.png" />
        <Typography paragraph variant="body1">Our annotation template might look something like the following. Note that the ‘Annotations’ option is checked to allow this template to be used on annotations.</Typography>
        <img alt="" className={classes.wpImage} style={{ width: 600 }} src="/images/annotation9.png" />
        <Typography paragraph variant="body1">Once you have created the templates you need to add them to the taxonomy or dataset in order to use them. You do that via the details screen in the ‘Property Templates’ panel.</Typography>
        <img alt="" className={classes.wpImage} style={{ width: 600 }} src="/images/annotation8.png" />
        <Typography paragraph variant="body1">Once added it should look similar to the image below:</Typography>
        <img alt="" className={classes.wpImage} style={{ width: 600 }} src="/images/annotation4.png" />
        <Typography paragraph variant="body1">You also need to activate annotations by toggling the ‘Is Activated’ setting in the ‘Annotate’ panel, as shown below.</Typography>
        <img alt="" className={classes.wpImage} style={{ width: 600 }} src="/images/annotation3.png" />
        <Typography paragraph variant="body1">Once done, close the details view and go to the concept or resource you want to edit.</
        Typography>
        <Typography paragraph variant="body1">You need to add values for properties, such as the height for Eiffel Tower first. You should then see the annotation icon next to each property value in the templates section of the details for a concept, as shown in the image below.</Typography>
        <img alt="" className={classes.wpImage} style={{ width: 600 }} src="/images/annotation6.png" />
        <Typography paragraph variant="body1">Clicking on the icon will display a screen where you can add the annotation detail.</Typography>
        <img alt="" className={classes.wpImage} style={{ width: 600 }} src="/images/annotation5.png" />
        <Typography paragraph variant="body1">Our annotation detail might look something like:</Typography>
        <img alt="" className={classes.wpImage} style={{ width: 600 }} src="/images/annotation1.png" />
        <Typography paragraph variant="body1">In our example we have used a specific data type (Any URI) for our ‘Stated By’ property to hold the website URL. That doesn’t have to be the case of course. There are many ways you could hold information like this, such as just a name (e.g. ‘Britannica’), or even a relationship to a concept in another taxonomy (e.g. ‘Britannica’ as a concept).</Typography>
        <Typography paragraph variant="body1">Click ‘Close’. The icon turns green to indicate that there is active annotation detail for this value. You can click on the icon to further edit the annotation.</Typography>
        <img alt="" className={classes.wpImage} style={{ width: 600 }} src="/images/annotation7.png" />
        <Typography paragraph variant="body1">Note that annotations are specific to value on a property. If you change the value the annotation will no longer apply.</Typography>
        <Typography paragraph variant="body1">That’s all there is to it. Annotations add a lot of potential for enhancing information. Plus it is all done with standards, so the information you create will work in any system that can support those standards.</Typography>
      </div>
    );
  }
}

export default withStyles(styles)(AnnotationInformation);